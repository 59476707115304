import $ from "jquery";
import SumoSelect  from 'sumoselect';

window.initSelect = function (element) {
    element.SumoSelect({
        placeholder: element.attr('placeholder') ? element.attr('placeholder') : 'Выберите',
        forceCustomRendering: true,
        captionFormat:'{0} Выбрано', 
        captionFormatAllSelected:'{0} Все выбраны!',
    });
}

document.addEventListener('DOMContentLoaded', function(){

    $('.jsSelect').each(function(){
        window.initSelect($(this));
    })
})