const hidePreloader = () => {
  const preloaderNode = document.querySelector('.jsPreloader');
  const contentNode = document.querySelector('.jsPageContent');

  if (preloaderNode) {
    preloaderNode.classList.add('_hidden');

    setTimeout(() => {
      preloaderNode.remove();
    }, 500);
  }

  if (contentNode) {
    contentNode.classList.add('_shown');
  }
};

export default hidePreloader;
