import anime from 'animejs/lib/anime.es.js';

window.updateCalc = function(values){
    let list = document.querySelectorAll('.constructor__result-item');

    for (let index = 0; index < list.length; index++) {
        const item = list[index];
        let strong = item.querySelector('.constructor__result-label strong')

        anime({
            targets: strong,
            innerHTML: values[index],
            round: 1,
            easing: 'easeInOutExpo',
        });
    
    }

    return;
}
// window.updateCalc([120, 200, 5000, 1200])

let more = document.querySelectorAll('.jsConstructorMore');

for (let index = 0; index < more.length; index++) {
    const item = more[index];
    let parent = item.closest('.jsConstructorList');

    item.addEventListener('click', ()=>{
        parent.classList.add('is-show');
        item.classList.add('is-hide')
    })
}
