import {Tooltip} from 'bootstrap';

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
// const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

for (let index = 0; index < tooltipTriggerList.length; index++) {
    const item = tooltipTriggerList[index];
    let tooltip = new Tooltip(item,
        {
            placement: 'top',
            html: true,
        })
}